<template>
  <!--begin::Mixed  Widget 1-->
  <div class="card card-custom bg-gray-100 card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 bg-danger py-5">
      <h3 class="card-title font-weight-bolder text-white">
        Bienvenue {{$store.back.getUser().prenom}},
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body p-0 position-relative overflow-hidden">
      <!--begin::Chart-->
      <apexchart
        class="card-rounded-bottom bg-danger"
        :options="chartOptions"
        :series="series"
        type="area"
      ></apexchart>
      <!--end::Chart-->
      <!--begin::Stats-->
      <div class="card-spacer mt-n40 dash-card-menu">
        <!--begin::Row-->
        <div class="row m-0">
          <a class="col-sm-3 offset-sm-2 rounded-xl  px-6 py-8 mb-7  mr-7 bg-light-success" href="/#/client/nouveau" style="text-align: center;">
            <span class="d-block my-2">
              <i class="fas fa-gem text-success fa-3x"></i>
            </span>
            <a class="font-weight-bold font-size-h6 text-success">
              Ajouter un Client
            </a>
          </a>
          <a class="col-sm-3 rounded-xl  px-6 py-8 mb-7  mr-7 bg-light-warning" v-b-modal.calendar style="text-align: center;">
            <span class="d-block my-2">
              <i class="fas fa-calendar text-warning fa-3x"></i>
            </span>
            <a class="font-weight-bold font-size-h6 text-warning">
              Ajouter un RDV
            </a>
          </a>
        </div>
        <div class="row m-0">
          <router-link v-for="(m,i) in menu" :key="i" :to="m.path" v-slot="{ href, navigate, isActive, isExactActive }">
            <a class="col-sm-3 rounded-xl  px-6 py-8 mb-7  mr-7" :class="'bg-light-'+m.color" :href="href" style="text-align: center;">
              <span class="d-block my-2">
                <i :class="m.icon+' '+'text-'+m.color" class="fa-3x"></i>
              </span>
              <a class="font-weight-bold font-size-h6" :class="'text-'+m.color">
                {{m.titre}} ({{m.nb}})
              </a>
            </a>
          </router-link>
        </div>
        <!--end::Row-->
      </div>
      <!--end::Stats-->
      <!--end::Body-->
    </div>
    <!--end::Mixed Widget 1-->
    <b-modal id="calendar" title="Calendrier" ref="calendar">
      <b-form>
        <b-form-group label="Titre:">
          <b-form-input v-model="calendarTmp.name" type="text" required placeholder="Entre le titre"></b-form-input>
        </b-form-group>
        <b-form-group label="Detail:">
          <b-form-input v-model="calendarTmp.detail" type="text" required placeholder="Entre le deail"></b-form-input>
        </b-form-group>
        <b-form-group label="Debut:">
          <b-row>
            <b-col size="md-6">
              <b-form-datepicker v-model="calDateStart"></b-form-datepicker>
            </b-col>
            <b-col size="md-6">
              <b-form-timepicker v-model="calTimeStart"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group label="Fin:">
          <b-row>
            <b-col size="md-6">
              <b-form-datepicker v-model="calDateStart" readonly></b-form-datepicker>
            </b-col>
            <b-col size="md-6">
              <b-form-timepicker v-model="calTimeEnd"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right mr-2" @click="saveCal()">
            Sauvegarder
          </b-button>
          <b-button variant="danger" class="float-right mr-2" @click="$refs.calendar.hide();">
            Fermer
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "widget-1",
  components: {
  },
  data() {
    return {
      menu:[
        {
          "titre":"Clients",
          "path":"/client/liste",
          "icon":"fas fa-gem",
          "color":"success",
          "nb":0
        },
        {
          "titre":"Proprietes",
          "path":"/propriete/liste",
          "icon":"fas fa-key",
          "color":"warning",
          "nb":0
        },
        {
          "titre":"Prestataires",
          "path":"/prestataire/liste",
          "icon":"fas fa-hard-hat",
          "color":"info",
          "nb":0
        },
        {
          "titre":"Projets",
          "path":"/projet/liste",
          "icon":"fas fa-building",
          "color":"danger",
          "nb":0
        },               
        {
          "titre":"Agenda",
          "path":"/agenda/liste",
          "icon":"fas fa-calendar",
          "color":"primary",
          "nb":0,
        },
        {
          "titre":"Utilisateurs",
          "path":"/user/liste",
          "icon":"fas fa-user",
          "color":"secondary",
          "nb":0
        }
      ],
      strokeColor: "#D13647",
      chartOptions: {},
      series: [
        /*{
          name: "Net Profit",
          data: [30, 45, 32, 70, 40, 40, 40]
        },
        {
          name: "Net Jessy",
          data: [35, 50, 37, 75, 45, 45, 45]
        }*/
      ],
      calDateStart:null,
      calTimeStart:"12:00",
      calTimeEnd:"12:30",
      calendarTmp:{
        name: null,
        start: null,
        end: null,
        detail: null,
        url:`/${location.hash}`
      },
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted() {
    // reference; kt_mixed_widget_1_chart
    this.chartOptions = {
      chart: {
        type: "area",
        height: 200,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        sparkline: {
          enabled: true
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 5,
          left: 0,
          blur: 3,
          color: this.strokeColor,
          opacity: 0.5
        }
      },
      plotOptions: {},
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: "solid",
        opacity: 0
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [this.strokeColor]
      },
      xaxis: {
        categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        },
        crosshairs: {
          show: false,
          position: "front",
          stroke: {
            color: this.layoutConfig("colors.gray.gray-300"),
            width: 1,
            dashArray: 3
          }
        }
      },
      yaxis: {
        show: false,
        min: 0,
        max: 80,
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        }
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0
          }
        },
        hover: {
          filter: {
            type: "none",
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: this.layoutConfig("font-family")
        },
        y: {
          formatter: function(val) {
            return "$" + val + " thousands";
          }
        },
        marker: {
          show: false
        }
      },
      colors: ["transparent"],
      markers: {
        colors: [this.layoutConfig("colors.theme.light.danger")],
        strokeColor: [this.strokeColor],
        strokeWidth: 3
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0
        }
      }
    };
  },
  watch: {
    calDateStart(){
      this.calendarTmp.start = this.calDateStart+' '+this.calTimeStart;
      this.calendarTmp.end = this.calDateStart+' '+this.calTimeEnd;
    },
  },
  methods: {
    saveCal(){
      this.$store.back.ajax('/calendrier/create', this.calendarTmp, (res) => {
        if(res.status === true){
          this.$bvToast.toast(`Votre rdv a ete sauvegarde !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
          this.$refs.calendar.hide();
        }
      });
    },
  },
  beforeMount() {
    this.$store.back.ajax('/dashboard/info', null, (res) => {
      if(res.status){
        this.menu.forEach((m) => {
          var check = res.data[m.titre];
          if(check != undefined){
            m.nb = res.data[m.titre];
          }
        });
      }
    })
  }
};
</script>
